export class KeysHolderNew {
    constructor(name) {
        this.name = name
    }


    checkKey = async () => {
        if(!this.key) this.key = await this.getKey();
        return Boolean(this.key)
    }

    getKey = async () => {
        if(this.key) return this.key
        const pass = sessionStorage.getItem("ad-ref") ?? prompt('Введите ключ для работы с разделом ' + this.name + '"', 'NisseTeam2')
        //let pass = 'NisseTeam2';
        pass && sessionStorage.setItem("ad-ref", pass)
        const key = await window.crypto.subtle.importKey(
            'raw',
            await crypto.subtle.digest('SHA-256', this._encode(pass)),
            { name: 'AES-CBC' },
            false,
            ["encrypt", "decrypt"],
        ); 
        return key;
    }

    generateIv = async () => await window.crypto.getRandomValues(new Uint8Array(16))

    encrypt = async (data, key) => {
        const encoded = this._encode(data)
        const iv = await this.generateIv()
        const cipher = await window.crypto.subtle.encrypt({
            name: 'AES-CBC',
            iv
        }, key, encoded)
    
        return {
                cipher,
                iv
            }
    }
    decrypt = async (cipher, key, iv) => {
        const encoded = await window.crypto.subtle.decrypt({
            name: 'AES-CBC',
            iv
        }, key, cipher)
    
        return this._decode(encoded)
    }

    packOnBase64 = buffer => window.btoa(
        String.fromCharCode.apply(null, new Uint8Array(buffer))
    )

    unpackFromBase64 = packed => {
        const string = window.atob(packed)
        const buffer = new ArrayBuffer(string.length)
        const bufferView = new Uint8Array(buffer)
    
        for (let i = 0; i < string.length; i++) {
            bufferView[i] = string.charCodeAt(i)
        }
    
        return buffer
    }

    _decode = byteStream => {
        const decoder = new TextDecoder()
    
        return decoder.decode(byteStream)
    }
    _encode = data => {
        const encoder = new TextEncoder()
    
        return encoder.encode(data)
    }

    encode = async (json) => {
        this.key = await this.getKey();
        const {
            cipher,
            iv
        } = await this.encrypt(json, this.key)
        const base64iv = this.packOnBase64(iv);
        const base64cipher = this.packOnBase64(cipher);
        const sendData = `${base64iv}${base64cipher}`;
        return sendData
    }

    decode = async (str) => {
        try {
            this.key = await this.getKey()
            const string = str;
            const iv = string.slice(0, 24);
            const data = string.slice(24);
            const unpack64iv = this.unpackFromBase64(iv);
            const unpack64data = this.unpackFromBase64(data);
            const decryptData = await this.decrypt(unpack64data, this.key, unpack64iv);
            return JSON.parse(decryptData);
        } catch {
            return undefined
        }
    }
}

export const projectKeyHolderNew = new KeysHolderNew('Проекты');