<template>
  <component :is="selectMode ? 'div' : 'router-link'"  draggable="false" :to="'/tender/' + entry.notification.id + '/info?type=supmain/notifications'" @click="$emit('select', entry)">
	<div class="application-entry">
		<div class="request-header">
						<h3>{{ entry.notification.customer.fullName }}</h3>

						<h4>{{ entry.notification.maxPrice | formatPrice }}</h4>
					</div>
					<div class="request-wrap">
						<div class="request-description">
							<h5>№ {{ entry.notification.regnum }}
								<span class="request-status risc" v-if="entry.riskAssessment==='HIGH'">Высокорисковая заявка</span>
								<span class="request-status priority" v-if="entry.highPriority">Высокий приоритет</span>
							</h5>
							<p>{{ entry.notification.theme }}</p>
						</div>
						<div class="request-info" style="width: 40%;">
							<div style="width: 33%;">
								<h6>публикация</h6>
								<p>{{ entry.notification.publishDateTime | formatDate }}</p>
							</div>
							<div style="width: 33%;">
								<h6>вскрытие</h6>
								<p>{{ entry.notification.endDateTime | formatDate }}</p>
							</div>
							<div style="width: 34%;">
								<h6>статус</h6>
								<p>{{ applicationStatusDict[entry.applicationStatus] }}</p>
							</div>
							<div style="width: 66%;">
								<h6>юр. лицо</h6>
								<p>{{ entry.ourOrg ? entry.ourOrg.shortName : entry.differentOrgName }}</p>
							</div>
							<div style="width: 34%;">
								<h6>ответственный</h6>
								<p><router-link
                    :to="'/employee/' + entry.offerExpert.id+'/info'"
                    v-if="entry.offerExpert"
                >{{ offerExpertName }}</router-link> <router-link
                      :to="'/employee/' + entry.formalExpert.id+'/info'"
                      v-if="entry.formalExpert"
                  >{{ formalExpertName }}</router-link></p>
							</div>
						</div>
					</div>
	</div>
	</component>
</template>
<script>
import {applicationStatusDict} from "@/modules/NSI";
import {dictToObject, getExpertShortName} from "@/modules/CommonUtils";

export default {
	name: 'ApplicationEntry',
	props: ['entry', 'selectMode'],
  data() {
    return {
      applicationStatusDict: dictToObject(applicationStatusDict)
    }
  },
  computed: {
    offerExpertName(){
      return getExpertShortName(this.entry.offerExpert)
    },
    formalExpertName(){
      return getExpertShortName(this.entry.formalExpert)
    }
  }
}
</script>








