<template>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
        >
          ...
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{title}}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <main><register ref="register" :config="regConfig" select-mode="true" @select="$emit('select', $event); dialog = false" :presets="presets" :presetActiveTab="presetActiveTab"></register></main>
      </v-card>
    </v-dialog>

</template>

<script>
export default {
  name: "RegisterSelectDialog",
  props: ['regConfig', 'title', 'presets', 'presetActiveTab'],
  components: {
    Register: () => import('@/components/register/Register')
  },
  data () {
    return {
      dialog: false,
    }
  },
  methods: {
    onShowDialog(){
      //if(this.$refs.register != null)
        //this.$refs.register.reload()
    },
    onCloseDialog(){
    }
  },
  watch: {
    dialog: function (val) {
      if(val === true)
        this.onShowDialog()
      else if(val === false)
        this.onCloseDialog()
    }
  }
}
</script>

<style lang="scss" scoped>
.register-dialog .theme--light.v-btn.v-btn--has-bg {
  position: absolute;
  right: 2px;
  top: 1px;
  background: #fff !important;
  box-shadow: none;
  font-size: 20px;
  height: 53px;
  color: #000 !important;
  .v-btn__content {
    color: #000 !important;
  }
}
</style>