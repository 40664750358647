<template>
  <component :is="selectMode ? 'div' : 'router-link'"  draggable="false" :to="'/employee/' + entry.id + '/info'" @click="$emit('select', entry)">
		<div class="employee-entry">
			<div>
				<img :src="getImagePath(entry.imageFileId)"
                    style="display:block;width:60px;height:60px;border-radius:50%" v-if="entry.imageFileId" />
				<img src="~@/assets/male.png" v-else>

			</div>

			<div>
				<h2>{{ entry.surname }} {{ entry.name ? entry.name.charAt(0) + '.' : '' }} {{ entry.middleName ?
						entry.middleName.charAt(0) + '.' : ''
				}}</h2>
				<p>Эксперт с {{ (entry.jobs && entry.jobs.length > 0 ? entry.jobs[0].employmentDate : null) | formatDate
				}}</p>
				<span class="badge"><template v-if="!entry.inState">Внештатный</template><template v-else> {{ entry.jobs
						&& entry.jobs.length > 0 && entry.jobs[0].jobType ? jobTypeDict[entry.jobs[0].jobType] + ' занятость' : '—' }} </template></span>
			</div>

			<div>
				<div>
					<h6>организация</h6>
					<p><a href="#">{{ entry.jobs && entry.jobs.length > 0 && entry.jobs[0].organization ?
							(entry.jobs[0].organization.shortName != null && entry.jobs[0].organization.shortName.length
								> 0 ? entry.jobs[0].organization.shortName : entry.jobs[0].organization.fullName) :
							'—'
					}}</a></p>
				</div>
				<div>
					<h6>область квалификации</h6>
					<p>{{ entry.qualification | df }}</p>
				</div>
			</div>


			<div>
				<div>
					<h6>ученая степень</h6>
					<p>{{ entry.degree | df }}</p>
				</div>
				<div>
					<h6>специальность</h6>
					<p>{{ entry.specialty | df }}</p>
				</div>
			</div>


			<div>
				<div>
					<h6>электронная почта</h6>
					<p><a :href="'mailto:' + entry.email" v-if="entry.email">{{ entry.email }}</a><span
                      v-else>—</span></p>
				</div>
				<div>
					<h6>телефон</h6>
					<p>{{ entry.phoneNumber | df }}</p>
				</div>
			</div>
		</div>
	</component>
</template>
<script>
import { jobTypeDict } from '../../modules/NSI';
import {dictToObject, getImagePath} from '@/modules/CommonUtils'

export default {
	name: 'EmployeeEntry',
  methods: {getImagePath},
	props: ['entry', 'selectMode'],
	data() {
		return {
			jobTypeDict: dictToObject(jobTypeDict),
		};
	},
}
</script>
