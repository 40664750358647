<template>
	<component :is="selectMode ? 'div' : 'router-link'"  draggable="false" :to="'/tender/' + entry.id + '/info?type=supmain/notifications'" @click="$emit('select', entry)">
		<div class="notification-entry">
		<div>
			<h3>{{ entry.customer.fullName }}</h3>
			<h4>№ {{ entry.regnum }}</h4>
			<p>
				{{ entry.theme }}
			</p>
		</div>
		<div>
			<h3>{{ entry.maxPrice | formatPrice }}</h3>
			<div>
				<h4>публикация</h4>
				<p>{{ entry.publishDateTime | formatDate }}</p>
			</div>
			<div>
				<h4>вскрытие</h4>
				<p>{{ entry.endDateTime | formatDate }}</p>
			</div>
		</div>
		</div>
	</component>
</template>
<script>
export default {
	name: 'NotificationEntry',
	props: ['entry', 'selectMode']
}

</script>
