<template>
  <component :is="selectMode ? 'div' : 'router-link'" draggable="false" :to="'/contract/' + entry.id + '/info'"
             @click="$emit('select', entry)">

    <div class="application-entry">
      <div class="request-header">
        <h3>{{ customerName }}</h3>
        <h4>{{ entry.contractSum | formatPrice }}</h4>
      </div>
      <div class="request-wrap">
        <div class="request-description">
          <h5>№ {{ entry.contractNumber }} от {{ entry.startDate | formatDate }}</h5>
          <p>{{ entry.theme }}</p>
        </div>
        <div class="contract-info">
          <div>
            <h6>шифр</h6>
            <p>{{ entry.cypherPrefix }}–{{ entry.cypherCounter }}</p>
          </div>
          <div>
            <h6>даты</h6>
            <p>{{ entry.startDate | formatDate }} – {{ entry.endDate | formatDate }}</p>
          </div>
          <div>
            <h6>статус</h6>
            <p>{{ contractStatusDict[entry.contractStatus] }}</p>
          </div>
          <div>
            <h6>юр. лицо</h6>
            <p>{{ supplierName }}</p>
          </div>
          <div>
            <h6>ответственные</h6>
            <p><router-link
                :to="'/employee/' + entry.offerExpert.id+'/info'"
                v-if="entry.offerExpert"
            >{{ offerExpertName }}</router-link> <router-link
                  :to="'/employee/' + entry.formalExpert.id+'/info'"
                  v-if="entry.formalExpert"
              >{{ formalExpertName }}</router-link></p>
          </div>
        </div>
      </div>
    </div>
  </component>
</template>
<script>
import {contractStatusDict} from "@/modules/NSI";
import {dictToObject, getExpertShortName} from "@/modules/CommonUtils";

export default {
  name: 'ContractEntry',
  props: ['entry', 'selectMode'],
  data() {
    return {
      contractStatusDict: dictToObject(contractStatusDict)
    }
  },
  computed: {
    offerExpertName(){
      return getExpertShortName(this.entry.offerExpert)
    },
    formalExpertName(){
      return getExpertShortName(this.entry.formalExpert)
    },
    customerName(){
      if(this.entry.supplier.isOurOrg) return this.entry.customer.shortName  || this.entry.customer.fullName
      else return this.entry.supplier.shortName  || this.entry.supplier.fullName
    },
    supplierName(){
      if(!this.entry.supplier.isOurOrg) return this.entry.customer.shortName  || this.entry.customer.fullName
      else return this.entry.supplier.shortName  || this.entry.supplier.fullName
    }
  }
}
</script>








