<template>
	<router-link :is="selectMode ? 'div' : 'router-link'" draggable="false" :to="'/counterparty/' + entry.id" @click="$emit('select', entry)">
		<div class="counterparty-entry">
			
		<div>
			<h3>{{ entry.fullName | df }}</h3>			
			<div>
				<h4>сокращенное название</h4>
				<p>{{ entry.shortName | df }}</p>
			</div>
		</div>
		<div>			
			<div>
				<h4>регион</h4>
				<p>{{ entry.region ? entry.region.regionName : '—'}}</p>
			</div>
			<div>
				<h4>ИНН</h4>
				<p>{{ entry.inn | df }}</p>
			</div>
		</div>
		</div>
	</router-link>
</template>
<script>
export default {
	name: 'CounterpartyEntry',
	props: ['entry', 'selectMode']
}
</script>

