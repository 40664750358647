import { KeysHolderNew } from './KeysHolder.js';
import api from './api.js';

export const wiringKeyHolder = new KeysHolderNew('Wirings');

export const wGet = async (year = 2022) => {
  const res = await api.get(`supmain/wires/byYear/${year}`);
  return await res.payload;
};
export const wGetById = async (id) => {
  const res = await api.get(`/supmain/wires/${id}`);
  return await res.payload;
};

export const wDec = async (encArr) => {
  await wiringKeyHolder.checkKey();
  for (let i = 0; i < encArr.length; i++) {
    encArr[i].data = await wiringKeyHolder.decode(
      encArr[i].data
    );
  }

  return await encArr;
};
export const wDecOne = async (enc) => {
  // console.log("encArr", encArr)
  await wiringKeyHolder.checkKey();
  enc.data = await wiringKeyHolder.decode(enc.data);
  return await enc;
};

export const wUpdate = async (id, year, data) => {
  await wiringKeyHolder.checkKey();

  data = await wiringKeyHolder.encode(data);
  const res = await api.putJson(`/supmain/wires`, {
    id: id,
    year: year,
    data: data,
  });
  return res;
};
export const wPost = async (year, data) => {
  await wiringKeyHolder.checkKey();
  data = await wiringKeyHolder.encode(data);
  const res = await api.postJson('/supmain/wires', {
    year: year,
    data: data,
  })
  return res.status;
};
export const wDelete = async (id) => {
  await wiringKeyHolder.checkKey();
  const res = await api.delete(`/supmain/wires/${id}`)
  return res.status;
};
