<template>
  <tr>
    <td></td>

    <td>
      <router-link :to="`/wiring/${entry.id}`">
        {{ entry.id }}
      </router-link>
    </td>
    <td>{{ entry.data.sum.toLocaleString('ru') + '&nbsp;₽' }}</td>
    <td>
      {{ new Date(entry.data.paymentDate).toLocaleDateString('ru-RU') }}
    </td>
    <td>{{ entry.data.wiringType == 1 ? 'План' : 'Факт' }}</td>
    <td>{{ entry.data.paymentMethod.id == 1 ? 'Нал' : 'Безнал' }}</td>
    <td>
      <router-link :to="`/employee/${entry.data.expert.id}/info`">{{
        entry.data.expert ? entry.data.expert['fullName'] : ''
      }}</router-link>
    </td>
    <td>
      <router-link :to="`/counterparty/${entry.data.organization.id}`">{{
        entry.data.organization ? entry.data.organization['shortName'] : ''
      }}</router-link>
    </td>
    <!-- <td>
      {{
        entry.data.wiringTypeDir
          ? wiringTypeDirDict[entry.data.wiringTypeDir]
          : ''
      }}
    </td>
    <td>{{ entry.data.funds ? entry.data.funds.fund : '' }}</td> -->
    <td>{{ entry.data.article ? entry.data.article['article'] : '' }}</td>
    <td>
      <router-link :to="entry.data.contractor ? `/counterparty/${entry.data.contractor.id}` : ''">{{
        entry.data.contractor ? entry.data.contractor['fullName'] : ''
      }}</router-link>
    </td>
    <td>{{ entry.data.budgetYear }}</td>
    <td>
      <router-link
        :to="`/project/${entry.data.project ? entry.data.project.id : ''}`"
        >{{
          entry.data.project
            ? entry.data.project.shortName
              ? entry.data.project.shortName
              : entry.data.project
            : ''
        }}</router-link
      >
    </td>
    <td>
      <router-link
        :to="
          entry.data.contractId
            ? `/contract/${entry.data.contractId.id}/info`
            : '#'
        "
        >{{
          entry.data.contractId ? entry.data.contractId['contractNumber'] : ''
        }}</router-link
      >
    </td>
    <td><a href="#">Краснов С.М.</a></td>
    <td>{{ entry.data.comment ? entry.data.comment : '' }}</td>
    <div class="action">
      <div>
        <router-link :to="`/wiring/${entry.id}`">Просмотр</router-link>
        <a href="#">Редактировать</a>
        <a @click="removeEntry(entry.id)">Удалить</a>
      </div>
    </div>
  </tr>
</template>
<script>
import { wiringTypeDirDict } from '@/components/register/WireDict';
import { wDelete } from '@/modules/Wirings';
export default {
  name: 'WRegisterEntry',
  props: ['entry'],
  data() {
    return {
      wiringTypeDirDict: wiringTypeDirDict,
    };
  },
  methods: {
    async removeEntry(id) {
      const res = await wDelete(id);
      console.log(res);
      if(res === 200) {
        this.$emit('removeEntry', id);
      }
    }
  },
  beforeMount() {
    // console.log('reg entry:', this.entry);
  },
};
</script>
